import api from "@/modules/api";

export async function getFormById(id){
    return api.get(`/indicators/forms/${id}`)
}

export async function getFormsByDomain(domain){
    return api.get(`/indicators/forms/domain/${domain}`)
}

export async function getFormsByDomainAndRegionCode(domain, regionCode){
    return api.get(`/indicators/forms/domain/${domain}/region/${regionCode}`)
}

export async function getFormsByDomainAndRegionCodeAndContext(domain, regionCode, context){
    return api.get(`/indicators/forms/domain/${domain}/region/${regionCode}/context/${context}`)
}

export async function getFormsByDomainAndCznIdAndContext(domain, cznId, context){
    return api.get(`/indicators/forms/domain/${domain}/czn/${cznId}/context/${context}`)
}

export async function getFormsByDomainAndContext(domain, context) {
    return api.get(`/indicators/forms/domain/${domain}/context/${context}`)
}

export async function saveForm(form){
    return api.postJson(`/indicators/forms`, form)
}